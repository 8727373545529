<template>
  <!--添加预约商品-->
  <div class="page1">
    <!-- 内容 -->
    <div class="content">
      <h2 class="title">
        <span class="title_icon"></span>
        基础信息
      </h2>
      <el-form
        label-position="right"
        label-width="140px"
        :model="formInline"
        :rules="rules"
        :ref="'form'"
      >
        <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
          <el-select @change="queryParkList" v-model.trim="formInline.operationId" filterable>
            <!-- <el-option label="全部"
            value=""></el-option>-->
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.park_name')" prop="slaveRelation">
          <el-select v-model.trim="formInline.slaveRelation" clearable placeholder="请选择">
            <el-option
              v-for="item in slaveRelation"
              :key="item.code"
              :label="item.parkName"
              :value="item.parkId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="可预约数量" prop="resourceNum" class="parknum">
          <el-input v-model="formInline.resourceNum" style="width: 150px;" maxlength="5"></el-input>
          <span class="spanA">个</span>
        </el-form-item>
        <el-form-item label="预约押金" required class="parknum" style="width: 350px">
          <el-radio
            label="0"
            style="margin-right: 0px;"
            v-model="formInline.yajinType"
            @change="changeRadio"
          >
            <el-input
              v-model="formInline.yajin"
              style="width: 150px; margin-left :5px "
              :disabled="formInline.yajinType == 1 ? true:false"
              oninput="value=value.replace(/[^\d.]/g,'')"
              :maxlength="8"
            ></el-input>
          </el-radio>
          <span class="spanA">元</span>
          <el-radio label="1" v-model="formInline.yajinType" @change="changeRadio">免押金</el-radio>
        </el-form-item>
        <el-form-item label="预约日期" prop="searchDateS">
          <el-date-picker
            v-model="formInline.searchDateS"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="showLog"
          ></el-date-picker>
        </el-form-item>

        <div class="timeDer">
          <el-form-item label="预约时间" prop="startTime" style="width:300px">
            <el-time-select
              style="z-index:99"
              placeholder="起始时间"
              v-model="formInline.startTime"
              :picker-options="{
      start: '00:00',
      step: '00:30',
      end: '23:59',
       maxTime: formInline.endTime
    }"
            ></el-time-select>
          </el-form-item>

          <div class="timeDerEnds">
            <el-form-item label="-" prop="endTime" class="timeDerEnd">
              <el-time-select
                placeholder="结束时间"
                v-model="formInline.endTime"
                :picker-options="{
      start: '00:00',
      step: '00:30',
      end: '23:59',
      minTime:formInline.startTime
    }"
              ></el-time-select>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="泊位保留时长" placeholder="请输入车位保留时长" prop="timer">
          <el-input v-model.number="formInline.timer" style="width: 150px;"></el-input>
          <span class="spanA">分钟（0~60）</span>
        </el-form-item>

        <el-form-item label="预约开始时间" class="parknum" style="width: 550px" required>
          <el-radio
            label="0"
            style="margin-right: 0px;"
            v-model="formInline.ytimeType"
            @change="RadioytimeType"
          >
            <el-input
              v-model="formInline.minute"
              style="width: 150px; margin-left :5px  "
              :disabled="formInline.ytimeType == 1 ? true:false"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-radio>
          <span class="spanA">分钟内可预约(15~1440)</span>
          <el-radio
            label="1"
            style="margin-right: 0px;margin-top: 20px"
            v-model="formInline.ytimeType"
            @change="RadioytimeType"
          >
            <el-input
              v-model="formInline.day"
              style="width: 150px; margin-left :5px "
              :disabled="formInline.ytimeType == 0 ? true:false"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-radio>
          <span class="spanA">天内可预约(1~15)</span>
        </el-form-item>

        <el-form-item
          label="可取消时长"
          required
          class="parknum"
          style="width: 350px"
          v-if="formInline.yajinType==0"
        >
          <el-radio
            label="0"
            style="margin-right: 0px;"
            v-model="formInline.cancelType"
            @change="radioyCancelType"
          >
            <span class="spanA">不可取消</span>
          </el-radio>
          <div>
            <el-radio label="1" v-model="formInline.cancelType" @change="radioyCancelType">
              <span class="spanA">在预约到点前</span>
              <el-input
                v-model="formInline.cancelTime"
                style="width: 150px; margin-left :5px "
                :disabled="formInline.cancelType == 0 ? true:false"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="6"
              ></el-input>
              <span class="spanA">分钟，可主动取消预约订单</span>
            </el-radio>
          </div>
        </el-form-item>
        <el-form-item label="预约说明" prop="textarea">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="formInline.textarea"
            style="width: 350px;"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label>
          <el-button type="primary" @click="submmitAdd">{{ $t('button.preservation') }}</el-button>
          <el-button type="button" @click="goback">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  data() {
    let changeTomoney = (rule, value, callback) => {
      let values = (value.match(/\d+(\.\d{0,2})?/) || [""])[0];
      // console.log(value);
      if (value <= 0) {
        this.flag = false;
        callback(new Error("不能小于等于0"));
      } else if (values != value) {
        this.flag = false;
        callback(new Error("只能输入数字及小数点后两位"));
      } else {
        this.flag = true;
        callback();
      }
    };
    let checkBerthNum = (rule, value, callback) => {
      if (value != parseInt(value)) {
        return callback(new Error("必须为整数"));
      }
      if (value <= 0) {
        this.flag = false;
        callback(new Error("不能小于等于0"));
      } else {
        this.flag = true;
        callback();
      }
    };
    let checkBerthNum1 = (rule, value, callback) => {
      if (value != parseInt(value)) {
        return callback(new Error("必须为整数"));
      }
      if (value < 0) {
        this.flag = false;
        callback(new Error("不能小于0"));
      } else {
        this.flag = true;
        callback();
      }
    };
    let checkNum = (rule, value, callback) => {
      // console.log("rule-->", rule);
      if (value != parseInt(value)) {
        return callback(new Error("可提前预约时间必须为整数"));
      }
      if (value <= 0 || value > 15) {
        this.flag = false;
        callback(new Error("可提前预约时间需1-15天"));
      } else {
        this.flag = true;
        callback();
      }
    };
    let checkBowei = (rule, value, callback) => {
      // console.log("rule-->", rule);
      if (value != parseInt(value)) {
        return callback(new Error("可提前预约时间必须为整数"));
      }
      if (value < 0 || value > 60) {
        this.flag = false;
        callback(new Error("可提前预约时间需0-60分"));
      } else {
        this.flag = true;
        callback();
      }
    };
    return {
      dataDetail: "",
      tenantList: [],
      isEdit: false,
      isBtn: 1,
      formInline: {
        yajinType: "0",
        yajin: "",
        ytimeType: "0",
        minute: "",
        day: "",
        cancelType: "0",
        cancelTime: "",
        searchDateS: "",
        startTime: "",
        endTime: "",
        resourceNum: "1",
        timer: "",
        textarea: "",
        operationId: "",
        slaveRelation: "",
      },
      slaveRelation: [],
      rules: {
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "change",
          },
        ],
        slaveRelation: [
          {
            required: true,
            message: "请选择停车场",
            trigger: "change",
          },
        ],
        searchDateS: [
          {
            required: true,
            message: "请选择预约日期",
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择预约开始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择预约结束时间",
            trigger: "change",
          },
        ],
        timer: [
          {
            required: true,
            message: "请输入车位保留时长",
            trigger: "change",
          },
          { type: "number", message: "必须为数字" },
          { validator: checkBowei, trigger: "blur" },
        ],
        resourceNum: [
          {
            required: true,
            message: "请输入泊位数",
            trigger: "blur",
          },
          { validator: checkBerthNum, trigger: "blur" },
        ],
        yajin: [
          {
            required: true,
            message: "请输入押金",
            trigger: "change",
          },
          { validator: changeTomoney, trigger: "change" },
        ],
        tiqian: [
          {
            required: true,
            message: "请输入提前天数",
            trigger: "blur",
          },
          { type: "number", message: "必须为数字" },
          { validator: checkNum, trigger: "blur" },
        ],
        quxiao: [
          {
            required: true,
            message: "请输入可取消时长",
            trigger: "blur",
          },
          { validator: checkBerthNum1, trigger: "blur" },
        ],
        tuihuan: [
          {
            required: true,
            message: "请输入取消退还金额",
            trigger: "blur",
          },
          { validator: changeTomoney, trigger: "blur" },
        ],
        textarea: [
          {
            required: true,
            message: "请输入预约说明",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // console.log("this.$route.query===>", this.$route.query);
    this.getTenantList();
    if (this.$route.query.appointmentGoodId) {
      this.isEdit = true;
      this.inItData(this.$route.query.appointmentGoodId);
      // console.log(" this.isEdit==1?", this.isEdit);
    } else {
      this.isEdit = false;
      // console.log(" this.isEdit==2?", this.isEdit);
    }
  },
  watch: {
    // formInline: {
    //   handler(oldVal, newVal) {
    //     for (let item in this.formInline) {
    //       if (this.formInline[item] && this.formInline.type.length > 0) {
    //         // console.log("item", item);
    //         // console.log("this.formInline", this.formInline[item]);
    //         this.isBtn = 0;
    //       } else {
    //         this.isBtn += 1;
    //         return;
    //       }
    //       // console.log(this.isBtn);
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    // 查询车场列表
    queryParkList(val) {
      // console.log(val);
      this.formInline.operationId = val;
      this.formInline.slaveRelation = "";
      this.selected = [];
      if (!this.formInline.operationId) {
        this.formInline.equipmentId = "";
        return;
      }
      let url =
        "/acb/2.0/systems/loginUser/parkNameList?slaveRelations=0,2&parkTypes=3&dataSource=2&operationId=" +
        this.formInline.operationId;
      this.$axios.get(url).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.slaveRelation = res.value;
      });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    goback() {
      this.$router.go(-1);
    },
    showLog() {
      let date = new Date(+new Date() + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
      // console.log(date); // 2018-03-26 15:41:10

      // console.log(dateFormat(this.formInline.searchDateS[0], "yyyy-MM-dd"));

      let ts = this.tab(
        date,
        dateFormat(this.formInline.searchDateS[0], "yyyy-MM-dd")
      );
      if (!ts) {
        this.formInline.searchDateS = "";
        this.$message("预约时间需要大于今天");
      }
    },
    tab(date1, date2) {
      let t = false;
      var oDate1 = new Date(date1);
      var oDate2 = new Date(date2);
      if (oDate1.getTime() > oDate2.getTime()) {
        // console.log("本地时间大");
      } else {
        t = true;
        // console.log("预约时间个大");
      }
      return t;
    },
    changeRadio(e) {
      if (this.formInline.yajinType == 1) {
        this.formInline.yajin = "";
      }
    },
    RadioytimeType() {
      if (this.formInline.ytimeType == 0) {
        this.formInline.day = "";
      } else {
        this.formInline.minute = "";
      }
    },
    radioyCancelType() {
      if (this.formInline.cancelType == 0) {
        this.formInline.cancelTime = "";
      }
    },
    submmitAdd() {
      // yajingType=0 不免押金
      if (this.formInline.yajinType == 0) {
        if (this.formInline.yajin) {
          if (this.formInline.yajin <= 0) {
            this.$message("预约押金需要大于0");
            return;
          }
        } else {
          this.$message("预约押金不能为空");
          return;
        }
      }

      // 预约开始时间 0  分钟 1 天
      if (this.formInline.ytimeType == 0) {
        if (this.formInline.minute) {
          if (this.formInline.minute != parseInt(this.formInline.minute)) {
            this.$message("预约开始时间必须为整数");
            return;
          }
          if (this.formInline.minute <= 14 || this.formInline.minute > 1440) {
            this.$message("预约开始时间需15-1440分");
            return;
          }
        } else {
          this.$message("预约开始时间不能为空");
          return;
        }
      } else {
        if (this.formInline.day) {
          if (this.formInline.day != parseInt(this.formInline.day)) {
            this.$message("预约开始时间必须为整数");
            return;
          }
          if (this.formInline.day < 1 || this.formInline.day > 15) {
            this.$message("预约开始时间需1-15天");
            return;
          }
        } else {
          this.$message("预约开始时间不能为空");
          return;
        }
      }

      // 免押金不校验可提前取消时长 yajingType=0 不免押金
      if (this.formInline.yajinType == 0) {
        // cancelType 0 不可取消
        if (this.formInline.cancelType == 1) {
          if (this.formInline.cancelTime) {
            if (this.formInline.cancelTime <= 0) {
              this.$message("可取消时需要大于0");
              return;
            }
          } else {
            this.$message("可取消时长不能为空");
            return;
          }
        }
      }

      let opt = {
        operationId: this.formInline.operationId,
        parkId: this.formInline.slaveRelation,
        appointmentBerthNumber: this.formInline.resourceNum,
        depositMoneyStr:
          this.formInline.yajinType == 1 ? "-1" : this.formInline.yajin,
        // yajinType: this.formInline.yajinType,
        appointmentStartDate: dateFormat(
          this.formInline.searchDateS[0],
          "yyyy-MM-dd"
        ),
        appointmentEndDate: dateFormat(
          this.formInline.searchDateS[1],
          "yyyy-MM-dd"
        ),
        appointmentStartTime: this.formInline.startTime + ":00",
        appointmentEndTime: this.formInline.endTime + ":00",
        berthRetentionTime: this.formInline.timer,
        advanceDate: this.formInline.day,
        advanceMinute: this.formInline.minute,

        // ytime:
        //   this.formInline.ytimeType == 1
        //     ? this.formInline.day
        //     : this.formInline.minute,
        cancellTime:
          this.formInline.cancelType == 0 ? "-1" : this.formInline.cancelTime,
        description: this.formInline.textarea,
        // cancelType: this.formInline.cancelType,
      };
      // console.log("frominen==>", opt);
      this.$refs["form"].validate((valid) => {
        // console.log("valid==>", valid);
        if (valid) {
          this.$confirm("确定保存当前界面信息", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.dataDetail.appointmentGoodId) {
                opt.appointmentGoodId = this.dataDetail.appointmentGoodId;
                this.$axios
                  .post("/acb/2.0/parkAppointmentGood/update", {
                    data: opt,
                  })
                  .then((res) => {
                    if (res.state == 0) {
                      this.goback();
                    }
                    // else {
                    //   this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    //     confirmButtonText: this.$t('pop_up.Determine')
                    //   });
                    // }
                  });
              } else {
                this.$axios
                  .post("/acb/2.0/parkAppointmentGood/add", {
                    data: opt,
                  })
                  .then((res) => {
                    if (res.state == 0) {
                      this.goback();
                    }
                  });
              }
            })
            .catch(() => {});
        }
      });
    },
    inItData(id) {
      // if (this.dataDetail.appointmentBerthNumber == -1) {
      //   this.formInline.resource = "-1";
      //   this.formInline.resourceNum = "1";
      // } else {
      //   this.formInline.resource = "2";
      //   this.formInline.resourceNum = this.dataDetail.appointmentBerthNumber;
      // }
      this.$axios
        .post("/acb/2.0/parkAppointmentGood/getById", {
          data: {
            appointmentGoodId: id,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.dataDetail = res.value;
            // console.log("this.dataDetail.operationId-->", this.dataDetail);
            this.formInline.operationId = this.dataDetail.operationId;
            this.queryParkList(this.formInline.operationId);
            this.formInline.slaveRelation = this.dataDetail.parkId;
            this.formInline.resourceNum = this.dataDetail.appointmentBerthNumber;
            if (this.dataDetail.depositMoney == -1) {
              this.formInline.yajinType = "1";
            } else {
              this.formInline.yajinType = "0";
              this.formInline.yajin = this.dataDetail.depositMoneyStr;
            }
            this.formInline.searchDateS = [
              this.dataDetail.appointmentStartDate,
              this.dataDetail.appointmentEndDate,
            ];
            this.formInline.startTime = this.dataDetail.appointmentStartTime;
            this.formInline.endTime = this.dataDetail.appointmentEndTime;
            this.formInline.timer = this.dataDetail.berthRetentionTime;

            // this.formInline.tiqian = this.dataDetail.advanceDate;
            if (this.dataDetail.advanceDate) {
              this.formInline.day = this.dataDetail.advanceDate;
              this.formInline.ytimeType = "1"
            }

            if (this.dataDetail.advanceMinute) {
              this.formInline.minute = this.dataDetail.advanceMinute;
              this.formInline.ytimeType = "0"
            }

            if (this.dataDetail.cancellTime == -1) {
              this.formInline.cancelType = "0";
            } else {
              this.formInline.cancelType = "1";
              this.formInline.cancelTime = this.dataDetail.cancellTime;
            }
            this.formInline.textarea = this.dataDetail.description;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.title {
  font-size: 14px;
}

.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .inputTip {
    margin-left: 20px;
    color: #999;
  }

  .oRelative {
    position: relative;

    span {
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}

.timeDer {
  position: relative;

  .timeDerEnds .timeDerEnd {
    position: absolute;
    top: 0px;
    left: 240px;
  }
}

.bookingMoney {
  height: 50px;
  line-height: 50px;
}

.spanA {
  color: #606266;
}
</style>
<style>
.timeDer
  .timeDerEnds
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  margin-right: 0px;
}
</style>
